var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"merchant-list-view",attrs:{"fluid":""}},[_c('div',{staticClass:"ml-4",style:({
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			marginBottom: '10px'
		})},[_c('h1',[_vm._v("Merchants")])]),_c('template',{slot:"buttons"},[_c('Roundup-Button',{attrs:{"color":"white"},on:{"click":_vm.handleDownload}},[_vm._v("Export CSV")])],1),_c('v-data-table',{ref:"merchantTable",staticClass:"elevation-1 mx-4",attrs:{"headers":_vm.columns,"items":_vm.merchants,"sort-desc":[false, true],"multi-sort":"","loading":_vm.isLoading,"loading-text":"Fetching your donors..."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
		var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.navigateToMerchantDetail(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.multiplier",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(item.multiplier)+"x ")]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }