<template>
	<div
		:style="{
			flex: 1,
			boxShadow: '0 10px 20px rgba(0,0,0,.1)',
			marginLeft: '30px',
			marginTop: '25px',
			borderRadius: '10px',
			textAlign: 'center'
		}"
	>
		<div
			:style="{
				background: 'rgba(76, 125, 255, .1)',
				paddingTop: '15px',
				borderRadius: '10px 10px 0px 0px',
				paddingBottom: '5px',
				borderBottom: '2px solid #71788C'
			}"
		>
			<h3
				:style="{
					margin: 'auto',
					marginTop: 0,
					marginBottom: '2px',
					color: '#3B6FF7'
				}"
			>
				Personalization Tokens
			</h3>
			<p v-if="!copied" :style="{ fontSize: '10px', marginTop: 0 }">
				Click to copy to keyboard
			</p>
			<p
				v-else
				class="copy"
				:style="{ fontSize: '10px', marginTop: 0, fontWeight: 900 }"
			>
				Copied
			</p>
		</div>
		<div class="token-scroll" :style="{ maxHeight: '20em' }">
			<p class="token tooltip" @click="copyToken('{{first_name}}')">
				First Name
				<span class="tooltiptext"
					>Insert the first name of the donor you are messaging.</span
				>
			</p>
			<p class="token tooltip" @click="copyToken('{{last_name}}')">
				Last Name
				<span class="tooltiptext"
					>Insert the last name of the donor you are messaging.</span
				>
			</p>

			<h3>Links</h3>
			<p
				class="token tooltip"
				@click="copyToken('https://roundupapp.com/#/Login')"
			>
				Login
				<span class="tooltiptext"
					>Provide the login link to your donors</span
				>
			</p>
			<p
				class="token tooltip"
				@click="copyToken('https://roundupapp.com/#/Login/card')"
			>
				Add/Update Credit Card
				<span class="tooltiptext"
					>Automatically direct your users to provide a credit
					card</span
				>
			</p>
			<p
				class="token tooltip"
				@click="copyToken('https://roundupapp.com/#/Login/pre-plaid')"
			>
				Add/Update Bank Account
				<span class="tooltiptext"
					>Automatically direct your users to authorize roundups</span
				>
			</p>
			<p
				class="token tooltip"
				@click="copyToken('https://roundupapp.com/#/Login/mindonation')"
			>
				Set Monthly Minimum
				<span class="tooltiptext"
					>Automatically direct users to set their monthly minimum
					donation</span
				>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'token-list',
	data() {
		return {
			copied: false
		}
	},
	methods: {
		async copyToken(value) {
			if (!navigator.clipboard) {
				// Clipboard API not available
				return
			}
			try {
				await navigator.clipboard.writeText(value)
				this.copied = true
				setTimeout(() => {
					this.copied = false
				}, 1000)
			} catch (err) {
				console.error('Failed to copy!', err)
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';
.token-scroll {
	overflow-y: scroll;
	text-align: center;
	margin-bottom: 10px;
	position: relative;
	.token {
		padding: 8px 3px;
		border-bottom: 2px solid $roundup-light-grey;
		margin-left: 10px;
		margin-right: 10px;
		&:hover {
			cursor: pointer;
		}
		&:active {
			color: #000;
		}
	}
	.tooltip {
		position: relative;
		.tooltiptext {
			visibility: hidden;
			margin-right: 20px;
			margin-bottom: 20px;
			width: 150px;
			top: 110%;
			left: 50%;
			margin-left: -75px;
			background-color: #fff;
			color: #000;
			font-size: 12px;
			text-align: center;
			border-radius: 6px;
			padding: 5px;
			boxshadow: 0 10px 20px rgba(0, 0, 0, 0.1);
			border: 2px solid $roundup-light-grey;
			position: absolute;
			z-index: 1;
		}
		&:hover {
			.tooltiptext {
				visibility: visible;
			}
		}
	}
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: $roundup-navy;
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
}
.copy {
	animation: fadeOut ease 1.5s;
	-webkit-animation: fadeOut ease 1s;
	-moz-animation: fadeOut ease 1s;
	-o-animation: fadeOut ease 1s;
	-ms-animation: fadeOut ease 1s;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-moz-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-o-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-ms-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
