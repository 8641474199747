var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
		flex: 1,
		boxShadow: '0 10px 20px rgba(0,0,0,.1)',
		marginLeft: '30px',
		marginTop: '25px',
		borderRadius: '10px',
		textAlign: 'center'
	})},[_c('div',{style:({
			background: 'rgba(76, 125, 255, .1)',
			paddingTop: '15px',
			borderRadius: '10px 10px 0px 0px',
			paddingBottom: '5px',
			borderBottom: '2px solid #71788C'
		})},[_c('h3',{style:({
				margin: 'auto',
				marginTop: 0,
				marginBottom: '2px',
				color: '#3B6FF7'
			})},[_vm._v(" Personalization Tokens ")]),(!_vm.copied)?_c('p',{style:({ fontSize: '10px', marginTop: 0 })},[_vm._v(" Click to copy to keyboard ")]):_c('p',{staticClass:"copy",style:({ fontSize: '10px', marginTop: 0, fontWeight: 900 })},[_vm._v(" Copied ")])]),_c('div',{staticClass:"token-scroll",style:({ maxHeight: '20em' })},[_c('p',{staticClass:"token tooltip",on:{"click":function($event){return _vm.copyToken('{{first_name}}')}}},[_vm._v(" First Name "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Insert the first name of the donor you are messaging.")])]),_c('p',{staticClass:"token tooltip",on:{"click":function($event){return _vm.copyToken('{{last_name}}')}}},[_vm._v(" Last Name "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Insert the last name of the donor you are messaging.")])]),_c('h3',[_vm._v("Links")]),_c('p',{staticClass:"token tooltip",on:{"click":function($event){return _vm.copyToken('https://roundupapp.com/#/Login')}}},[_vm._v(" Login "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Provide the login link to your donors")])]),_c('p',{staticClass:"token tooltip",on:{"click":function($event){return _vm.copyToken('https://roundupapp.com/#/Login/card')}}},[_vm._v(" Add/Update Credit Card "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Automatically direct your users to provide a credit card")])]),_c('p',{staticClass:"token tooltip",on:{"click":function($event){return _vm.copyToken('https://roundupapp.com/#/Login/pre-plaid')}}},[_vm._v(" Add/Update Bank Account "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Automatically direct your users to authorize roundups")])]),_c('p',{staticClass:"token tooltip",on:{"click":function($event){return _vm.copyToken('https://roundupapp.com/#/Login/mindonation')}}},[_vm._v(" Set Monthly Minimum "),_c('span',{staticClass:"tooltiptext"},[_vm._v("Automatically direct users to set their monthly minimum donation")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }