<template>
	<v-container fluid class="merchant-list-view">
		<div
			:style="{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginBottom: '10px'
			}"
			class="ml-4"
		>
			<h1>Merchants</h1>
		</div>
		<template slot="buttons">
			<Roundup-Button color="white" @click="handleDownload"
				>Export CSV</Roundup-Button
			>
		</template>
		<v-data-table
			:headers="columns"
			:items="merchants"
			:sort-desc="[false, true]"
			multi-sort
			class="elevation-1 mx-4"
			:loading="isLoading"
			loading-text="Fetching your donors..."
			ref="merchantTable"
		>
			<template v-slot:item.name="{ item }">
				<a
					@click="navigateToMerchantDetail(item)"
					style="text-decoration: none"
				>
					{{ item.name }}
				</a>
			</template>
			<template v-slot:item.multiplier="{ item }">
				{{ item.multiplier }}x
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import table from '@/mixins/table'
import download from '@/mixins/download'
import Modal from '../../ui/Modal'

import UiButton from '../../ui/Button'
import UiToggle from '../../ui/Toggle'
import UiLoader from '../../ui/Loader'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import TokenList from '../../layout/TokenList'

import Screen from '../../ui/Screen'

export default {
	name: 'merchant-list-view',
	components: {
		UiButton,
		Screen,
		Modal,
		UiToggle,
		UiLabel,
		TokenList,
		UiLoader,
		UiInput
	},
	mixins: [table, download],
	data() {
		return {
			keyMap: {
				name: 'Name',
				url: 'Website',
				multiplier: 'multiplier',
				institution_consent: 'Consented'
			},
			columns: [
				{ text: 'Name', value: 'name' },
				{ text: 'URL', value: 'url' },
				{ text: 'Multiplier', value: 'multiplier' }
			]
		}
	},
	computed: {
		...mapState({
			merchants: ({ merchantList }) => merchantList.items,
			isLoading: ({ merchantList }) => merchantList.isFetching,
			total: ({ merchantList }) => merchantList.total,
			authToken: ({ me }) => me.token,
			me: ({ me }) => me
		})
	},
	head: {
		title: {
			inner: 'Merchants'
		}
	},
	methods: {
		...mapActions(['getMerchants']),
		consentStatus(status) {
			switch (status) {
				case 'Consented':
					return 'Yes'
				default:
					return 'No'
			}
		},
		navigateToMerchantDetail(donor) {
			this.$store.commit('MERCHANT_DETAIL', { result: donor })

			this.$router.push({
				name: 'donors.merchants.detail',
				params: { donorId: 'merchant_' + donor.uuid }
			})
		},
		paramsChanged(params) {
			this.getMerchants()
		},
		handleDownload() {
			window.analytics.track('download_merchant_report')
			var params = this.getParameters()
			params.perPage = 1000
			params.page = 0
			this.postParameters('/merchants/csv', params, this.authToken)
		}
	},
	mounted() {
		this.getMerchants()
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.v-data-table {
	::v-deep .v-data-table-header {
		background-color: $roundup-bone !important;
		text-transform: uppercase !important;
	}
}
</style>
